<template>
    <div class="addressInfo">
        <el-form class="search" style="display: flex !important;" v-if="!view">
            <el-form-item label-width="70px" label="主体名称">
                <el-input class="search-input" maxLength="11" placeholder="请输入主体名称" prefix-icon="el-icon-search"
                    v-model="name" ref="searchInput1"></el-input>
            </el-form-item>
            <el-form-item label-width="90px" label="主体身份">
                <el-select v-model="selectedValue" placeholder="请选择">
                    <el-option v-for="item in role" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>&nbsp;&nbsp;&nbsp;
            </el-form-item>
            <div>
                <el-button type="primary" icon="el-icon-search"
                    @click="getTableData(1)">搜索</el-button>&nbsp;&nbsp;&nbsp;
                <el-button type="" icon="el-icon-refresh-right" @click="reset">重置</el-button>
            </div>
        </el-form>
        <div class="infoBox">
            <div class="table-data">
                <TableLoading :loadShow="loadShow"></TableLoading>
                <el-table :data="tableData" border>
                    <el-table-column prop="index" label="序号" width="70"></el-table-column>
                    <el-table-column label="主体名称">
                        <template slot-scope="{ row }">
                            {{ row.name }}
                            <span class="tabSpan fs11"
                                v-if="row.order_id === recently_id || row.hotel_id === recently_id">最近</span>
                        </template>

                    </el-table-column>
                    <el-table-column label="主体身份">
                        <template slot-scope="{ row }">
                            <StatusTab v-if="row.role === 1" type="cyan" text="客户" />
                            <StatusTab v-if="row.role === 2" type="pink" text="商家" />
                            <StatusTab v-if="row.role === 3" type="cyan" text="业务员" />
                            <StatusTab v-if="row.role === 4" type="pink" text="渠道" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="mtype" label="分佣类型" />
                    <el-table-column prop="money" label="金额" />
                    <el-table-column label="其他信息">
                        <template slot-scope="{ row }">
                            <span v-if="row.order_id">订单号：{{ row.order_id }}</span>
                            <span style="color: #1e9fff;" v-if="row.pre_id">预存号：{{ row.pre_id }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="create_time" label="创建时间" />
                    <el-table-column label="操作">
                        <template slot-scope="{ row }">
                            <StatusTab type="green" text="不可操作" />
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="pageBox">
                <el-pagination background layout="total, sizes, prev, pager, next, jumper"
                    @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
                    :page-sizes="[10]" :page-size="pageSize" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import {
    AdminGetCommissionRecordApi,
} from "@/common/api";
import TableLoading from "@/components/index/TableLoading.vue";
import StatusTab from "@/components/index/StatusTab.vue";
export default {
    components: {
        TableLoading,
        StatusTab,
    },
    props: {
        pointName: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            view: false,
            name: "",
            loadShow: false,
            pageNum: 1, // 当前页数
            total: 0, // 总页数
            pageSize: 10, // 每页显示条数
            tableData: [],
            selectedValue: "",
            recently_id: "",
            dialogFormVisible: false,
            role: [
                {
                    value: 1,
                    label: "客户",
                },
                {
                    value: 2,
                    label: "商家",
                },
                {
                    value: 3,
                    label: "业务员",
                },
                {
                    value: 4,
                    label: "渠道",
                },
            ]
        };
    },
    async created() {
        this.getTableData();
    },
    mounted() {
        // 监听回车事件
        document.addEventListener("keyup", this.enterSearch);
    },
    methods: {
        //重置
        reset() {
            this.name = "";
            this.selectedValue = "";
            this.pageNum = 1;
            this.getTableData();
        },
        // 每页条数改变时
        handleSizeChange(val) {
            // console.log(`每页 ${val} 条`);
            this.pageSize = val;
        },
        // 当前页改变时
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getTableData();
        },
        // 回车键搜索
        enterSearch(event) {
            if (event.keyCode === 13) {
                let isfocus1 = document.activeElement === this.$refs.searchInput1.$refs.input;
                // 判断光标是否聚焦在输入框中
                if (isfocus1) {
                    this.getTableData(1);
                }
            }
        },
        // 获取表格数据
        async getTableData(page) {
            if (page) this.pageNum = page;
            this.loadShow = true;
            let res = await AdminGetCommissionRecordApi({
                name: this.name.trim(),
                page: this.pageNum,
                role: this.selectedValue,
            });
            this.loadShow = false;
            if (!res) return;
            res.data.forEach((element, index) => {
                element.index = index + 1 + (this.pageNum - 1) * 10;
            });
            this.tableData = res.data;
            this.total = res.total;
            this.recently_id = res.recently_id;
        },
    },
    watch: {
        pointName: {
            immediate: true, // 立即监听
            handler(val, _) {
                this.name = val
                this.view = true
                this.getTableData();
            },
        }
    },
    beforeDestroy() {
        // 清楚dom事件
        document.removeEventListener("keyup", this.enterSearch);
    },
};
</script>

<style lang="less" scoped>
.mainData {
    display: flex;
    justify-content: space-between;
    color: #555;

    img {
        vertical-align: bottom;
        margin-right: 5px;
    }
}

.avatar {
    width: 30px !important;
    height: 30px !important;
    vertical-align: bottom;
    margin-right: 7px;
}
</style>
