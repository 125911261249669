<template>
    <div>
        <TableLoading :loadShow="loadShow" />
        <div style="">
            <el-form label-width="100px">
                <el-form-item label="日期范围：">
                    <el-select @change="getTableData()" style="width: 300px;" v-model="select_week" placeholder="请选择">
                        <el-option v-for="item in weekData" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
        </div>
        <div class="numberDiv">      
            <div class="greenDiv">
                <div style="position: relative;">
                    <div>￥{{ totalprofit }}</div>
                    <div>总利润</div>
                    <!-- 等于号注释 -->
                    <!-- <div class="dengyuhaoDiv">
                        <div class="dengyuhao"></div>
                        <div class="dengyuhao"></div>
                    </div> -->
                </div>
                <div>
                    <div>{{ orderCount }}</div>
                    <div>订单总数</div>
                </div>
            </div>
            <div class="blueDiv">
                <div>
                    <div>￥{{ totalMoney }}</div>
                    <div>收款总额</div>
                </div>
                <!-- 减号修改为分隔符 -->
                <div class="Separation"></div>
                <div>
                    <div>￥{{ totalOrderCommission }}</div>
                    <div>订单分佣总额</div>
                </div>
                <div class="Separation"></div>
                <div>
                    <div>￥{{ carCost }}</div>
                    <div>车费补贴</div>
                </div>
                <div class="Separation"></div>
                <div>
                    <div>￥{{ preReference }}</div>
                    <div>预存推荐分佣</div>
                </div>
                <div class="Separation"></div>
                <div>
                    <div>￥{{ totalMoneyTK }}</div>
                    <div>已退款总额</div>
                </div>
            </div>
        </div>
        <div class="cards">
            <Card v-for="item in index_data" :imgSrc="item.imgSrc" :key="item.title1" :title1="item.title1"
                :value1="item.value1" :title2="item.title2" :value2="item.value2" :title3="item.title3"
                :value3="item.value3" :symbol="item.symbol" />
        </div>
        <div class="tables ptb20">
            <div class="fs20 space3 color-#ccc">
                客户总数：<span class="fs25">{{ bc_data.customer_count || 0 }}</span>
            </div>
            <div class="fs20 space3 color-#ccc">
                商家总数：<span class="fs25">{{ bc_data.business_count || 0 }}</span>
            </div>
            <div class="fs20 space3 color-#ccc">
                业务员总数：<span class="fs25">{{ bc_data.salesman_count || 0 }}</span>
            </div>
            <div class="fs20 space3 color-#ccc">
                渠道总数：<span class="fs25">{{ bc_data.Hotel_count || 0 }}</span>
            </div>
        </div>
        <div class="mt20 pt10 flex" style="box-shadow: 0 0 5px #ccc; background-color: #fff; height: 440px;width:100%;">
            <div style="width: 50%;" id="bcEcharts"></div>
            <div style="width: 50%;" id="orderEcharts"></div>
        </div>
    </div>
</template>

<script>
import Card from "@/components/index/Card";
import StatusTab from "@/components/index/StatusTab";
import { AdminIndexInfoApi, AdminGetIndexBCApi } from "@/common/api";
import { timestampToString } from "@/common/common";
import TableLoading from "@/components/index/TableLoading.vue";
import * as echarts from "echarts";
export default {
    data() {
        return {
            loadShow: false,
            orderCount: 0,
            totalMoney: 0,
            totalOrderCommission: 0,
            preReference: 0,
            totalMoneyTK: 0,
            totalprofit: 0,
            carCost: 0,
            index_data: [],
            bc_data: {},
            business_data: [],
            customer_data: [],
            order_data: [],
            price_data: [],
            dates: [],
            weekData: [],
            select_week: ''
        };
    },
    components: {
        Card,
        StatusTab,
        TableLoading
    },
    created() {
        this.weekData = this.getWeeks()
        this.select_week = this.weekData[this.weekData.length - 1].value
    },
    mounted() {
        // 监听回车事件
        document.addEventListener("keyup", this.enterSearch);
        this.getTableData();
    },
    methods: {
        // 获取周
        getWeeks() {
            let start = Date.parse("2024-05-27 00:00:00");
            start = Math.floor(start / 1000)
            const now = Math.floor(new Date().getTime() / 1000)
            const data = []
            let index = 1
            while (now - start > 0) {
                let next = start + 7 * 24 * 3600
                let t1 = timestampToString(start * 1000)
                let t2 = timestampToString((next - 1) * 1000)
                data.push({
                    'value': `${t1}&${t2}`,
                    'label': `第${index}周（${t1.slice(0, 10)} ～ ${t2.slice(0, 10)}）`,
                })
                start = next
                index += 1
            }
            return data
        },

        //重置
        // 查看图片
        lookImg(imgUrl) {
            this.imgUsrcListrl = [imgUrl];
        },
        // 当前页改变时
        handleCurrentChange1(val1) {
            this.pageNum1 = val1;
            this.getTableData();
        },
        // 当前页改变时
        handleCurrentChange2(val2) {
            this.pageNum2 = val2;
            this.getTableData();
        },
        // 回车键搜索
        enterSearch(event) {
            if (event.keyCode === 13) {
                let isfocus1 =
                    document.activeElement === this.$refs.searchInput1.$refs.input;
                let isfocus2 =
                    document.activeElement === this.$refs.searchInput2.$refs.input;
                // 判断光标是否聚焦在输入框中
                if (isfocus1) {
                    this.pageNum1 = 1;
                    this.getTableData();
                }
                if (isfocus2) {
                    this.pageNum2 = 1;
                    this.getTableData();
                }
            }
        },
        // 获取表格数据
        async getTableData() {
            this.loadShow = true;
            let res = await Promise.all([AdminIndexInfoApi({select_week: this.select_week}), AdminGetIndexBCApi()]);
            this.loadShow = false;
            const res1 = res[0];
            const res2 = res[1];
            if (res1) {
                Object.assign(this, res1.data.totalInfo);
                this.index_data = res1.data.index_data;
                this.index_data.forEach((element, index) => {
                    if (index == 0) {
                        element.imgSrc = require("../../assets/img/money.png");
                    } else if (index == 3) {
                        element.imgSrc = require("../../assets/img/money.png");
                    } else {
                        element.imgSrc = require("../../assets/img/TX.png");
                    }
                });
            }
            if (res2) {
                this.bc_data = res2.data.bc_count;
                this.business_data = res2.data.business_data;
                this.customer_data = res2.data.customer_data;
                this.order_data = res2.data.order_data;
                this.price_data = res2.data.price_data;
                this.dates = res2.data.dates;
            }
            this.zxt();
            this.zzt();
        },
        // 折线图
        zxt() {
            const chartDom = document.getElementById("bcEcharts");
            const myChart = echarts.init(chartDom);
            const colors = ["#f56161ed", "#1e9fff"];
            const option = {
                color: colors,
                title: {
                    text: "七天新增用户",
                    textStyle: {
                        color: "#666",
                    },
                },
                tooltip: {
                    trigger: "axis",
                },
                legend: {
                    data: ["商家", "客户"],
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                xAxis: {
                    type: "category",
                    boundaryGap: false,
                    data: this.dates
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        name: "商家",
                        type: "line",
                        stack: "Total",
                        data: this.business_data,
                    },
                    {
                        name: "客户",
                        type: "line",
                        stack: "Total",
                        data: this.customer_data,
                    },
                ],
            };

            option && myChart.setOption(option);
        },
        // 柱状图
        zzt() {
            const chartDom = document.getElementById("orderEcharts");
            const myChart = echarts.init(chartDom);
            const colors = ["#5470C6", "#91CC75", "#EE6666"];
            const option = {
                color: colors,
                title: {
                    text: "七天订单交易量",
                    textStyle: {
                        color: "#666",
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                toolbox: {
                    feature: {
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                legend: {
                    data: ["订单数", "收款金额"],
                },
                xAxis: [
                    {
                        type: "category",
                        axisTick: {
                            alignWithLabel: true,
                        },
                        // prettier-ignore
                        data: this.dates,
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "订单数 /单",
                        position: "left",
                        alignTicks: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colors[0],
                            },
                        },
                        axisLabel: {
                            formatter: "{value}",
                        },
                    },
                    {
                        type: "value",
                        name: "收款金额 /元",
                        position: "right",
                        alignTicks: true,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: colors[1],
                            },
                        },
                        axisLabel: {
                            formatter: "{value}",
                        },
                    },
                ],
                series: [
                    {
                        name: "订单数",
                        type: "bar",
                        data: this.order_data,
                    },
                    {
                        name: "收款金额",
                        type: "bar",
                        yAxisIndex: 1,
                        data: this.price_data,
                    },
                ],
            };
            option && myChart.setOption(option);
        },
    },
    beforeDestroy() {
        // 清楚dom事件
        document.removeEventListener("keyup", this.enterSearch);
    },
};
</script>

<style lang="less" scoped>
.dengyuhaoDiv {
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translate(0, -50%)
}

.dengyuhao {
    width: 40px;
    height: 1px;
    background-color: #fff;
    box-shadow: 0 0 3px #ccc;
}

.Separation {
    border-right: 1px solid #fff;
    height: 50px;
    transform: translate(-10%, 0%);
    margin-left: -200px;
    /* width: 20px;
    height: 1px;
    background-color: #fff;
    flex: none !important; */
}

.table-search {
    padding-left: 20px;
}

.numberDiv {
    display: flex;
    width: 100%;

    .greenDiv {
        background: #32c777;
        display: flex;
        padding: 17px 0px;
        margin-right: 20px;
        >div {
            border-right: 1px solid #fff;
            padding: 0 40px;
            text-align: center;
            color: #fff;

            div:nth-of-type(1) {
                margin-bottom: 10px;
                font-size: 23px;
            }
        }

        >div:nth-of-type(2) {
            border: none;
        }
    }

    .blueDiv {
        flex: 1;
        background: #1e9fff;
        display: flex;
        padding: 17px 0px;
        justify-content: space-around;
        align-items: center;

        >div {
            flex: 1;
            /*border-right: 1px solid #fff;*/
            text-align: center;
            color: #fff;

            div:nth-of-type(1) {
                margin-bottom: 10px;
                font-size: 23px;
            }
        }

        >div:last-child {
            border: none;
        }
    }
}

.cards {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;

    >div {
        flex: 1;
        margin-right: 20px;
    }

    >div:last-child {
        margin-right: 0;
    }
}

.tables {
    display: flex;
    background-color: #fff;
    box-shadow: 0 0 5px #ccc;
    padding: 10px 0;
    justify-content: space-around;

    .pageBox {
        margin-top: 10px;
        text-align: right;
    }
}

.el-form-item {
    margin-bottom: 12px !important;
}

.register_detail>div {
    display: flex;
    margin: 10px 20px 20px 20px;
    border-bottom: 1px solid #eee;

    >div {
        margin: 0 10px;

        span {
            font-size: 20px;
            font-weight: 600;
            color: #ff0045;
        }
    }
}
</style>
