<template>
  <div class="addressInfo">
    <el-form class="search" style="display: flex !important;">
      <el-form-item label-width="75px" label="用户昵称">
        <el-input class="search-input" placeholder="请输入用户昵称" prefix-icon="el-icon-search" v-model="nickname"
          ref="searchInput2"></el-input>
      </el-form-item>
      <el-form-item label-width="70px" label="手机号">
        <el-input class="search-input" placeholder="请输入手机号" prefix-icon="el-icon-search" v-model="phone"
          ref="searchInput1">
        </el-input>
      </el-form-item>
      <el-form-item label-width="65px" label="身份">
        <el-select v-model="selectedValue" placeholder="请选择身份">
          <el-option v-for="item in roles" :key="item.value" :label="item.label" :value="item.value">
          </el-option> </el-select>&nbsp;&nbsp;&nbsp;
      </el-form-item>

      <div>
        <el-button type="primary" icon="el-icon-search" @click="getTableData(1)">搜索</el-button>&nbsp;&nbsp;&nbsp;
        <el-button type="" icon="el-icon-refresh-right" @click="reset">重置</el-button>
      </div>
    </el-form>
    <div class="infoBox">
      <div class="table-data">
        <TableLoading :loadShow="loadShow"></TableLoading>
        <el-table :data="tableData" border>
          <el-table-column prop="index" label="序号" width="70"></el-table-column>
          <el-table-column label="用户昵称" min-width="110">
            <template slot-scope="{ row }">
              <img :src="row.avatar" alt="" class="avatar">
              <span>{{ row.nickname }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="手机号" />
          <el-table-column label="身份">
            <template slot-scope="{ row }">
              <StatusTab v-if="row.role == 1" type="cyan" text="客户" />
              <StatusTab v-if="row.role == 2" type="pink" text="商家" />
            </template>
          </el-table-column>

          <el-table-column label="余额">
            <template slot-scope="{ row }">
              <div>{{ row.balance }}</div>
              <div style="color: gray; font-size: 13px;">最终余额：{{ row.final_balance }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="level" label="等级" />
          <el-table-column prop="integral" label="积分" />
          <el-table-column prop="reference_name" label="推荐人/渠道" />
          <el-table-column label="其他信息">
            <template slot-scope="{ row }">
              <el-popover placement="top" trigger="hover">
                <div style="text-align: left;">
                  <p>登录时间：{{ row.login_time }}</p>
                  <p>创建时间：{{ row.create_time }}</p>
                </div>
                <a slot="reference">查看</a>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="招商邀请码" width="110">
            <template slot-scope="{ row }">
              <el-popover placement="top" min-width="80">
                <p>招商邀请码（来自：{{ row.nickname }}）</p>
                <p>
                  <img :src="row.h5_ewm" alt="" width="200" />
                </p>
                <p>{{ row.h5_ewm_url }}</p>
                <div style="text-align: center; margin: 0">
                  <el-button type="primary" size="mini" @click="downloadEWM(row.h5_ewm)">下载</el-button>
                </div>
                <button slot="reference" class="my-btn">
                  查 看
                </button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="客户邀请码" width="110">
            <template slot-scope="{ row }">
              <el-popover placement="top" min-width="80">
                <p>客户邀请码（来自：{{ row.nickname }}）</p>
                <p>
                  <img :src="row.customer_ewm" alt="" width="200" />
                </p>
                <p>{{ row.customer_ewm_url }}</p>
                <div style="text-align: center; margin: 0">
                  <el-button type="primary" size="mini" @click="downloadEWM(row.customer_ewm)">下载</el-button>
                </div>
                <button slot="reference" class="my-btn">
                  查 看
                </button>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="120">
            <template slot-scope="{ row }">
              <!-- <div class="YTG"></div> -->
              <div class="flexA jus-bet">
                <el-button size="small" type="primary" @click="handelPoints('change', row.login_id, row.phone, row.nickname)">更改积分</el-button>
                <el-button size="small" type="primary" @click="handelPoints('view', row.login_id, row.phone, row.nickname)">积分记录</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageBox">
        <el-pagination background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10]" :page-size="pageSize"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 更改积分对话框 -->
    <div>
      <el-dialog :title="handelType == 'change' ? `更改积分--${pointPhone}--${pointName}` : `${pointPhone}--${pointName}的积分明细`"
        :visible.sync="pointsVisibel" custom-class="my-dialog" :close-on-click-modal="false" :width="handelType == 'change'?'460px':'1200px'">
        <div v-if="handelType == 'change'">
          <div style="margin: 40px 10px;">
            <label for=""><span>*</span>更改积分类型：</label>
            <el-radio-group v-model="pointsType">
              <el-radio :label="1">增加</el-radio>
              <el-radio :label="2">扣除</el-radio>
            </el-radio-group>
          </div>
          <div style="padding-bottom: 60px">
            <label for=""><span>*</span>积分值：</label>
            <el-input v-model="pointValue" type="number" placeholder="请输入积分" style="width: 240px;" />
          </div>
          <div slot="footer" class="dialog-footer" style="position: absolute; bottom: 10px;right: 20px;">
            <el-button size="small" class="mr10" @click="pointsVisibel = false">取 消</el-button>
            <el-button size="small" type="primary" @click="handelPoints('sure', '', '', '')">确 定</el-button>
          </div>
        </div>
        <div  v-else>
          <CommissionRecord :pointName="pointName"/>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  GetUserInfoApi,
  handelPointsInfoApi
} from "@/common/api";
import TableLoading from "@/components/index/TableLoading.vue";
import StatusTab from "@/components/index/StatusTab.vue";
import { downloadImage } from "@/common/common";
import CommissionRecord from "@/views/admin/CommissionRecord.vue"
export default {
  components: {
    TableLoading,
    StatusTab,
    CommissionRecord
  },
  data() {
    return {
      phone: "",
      nickname: "",
      loadShow: false,
      pageNum: 1, // 当前页数
      total: 0, // 总页数
      pageSize: 10, // 每页显示条数
      tableData: [],
      selectedValue: "",
      dialogFormVisible: false,
      roles: [
        {
          value: 1,
          label: "客户",
        },
        {
          value: 2,
          label: "商家",
        }
      ],
      login_id: '',
      pointName: '',
      pointsVisibel: false,
      pointsType: 1,
      pointValue: 0,
      pointPhone: '',
      pointsRecord: false,
      handelType: 'change'
    };
  },
  async created() {
    this.getTableData();
  },
  mounted() {
    // 监听回车事件
    document.addEventListener("keyup", this.enterSearch);
  },
  methods: {
    // 下载二维码
    downloadEWM(imgUrl) {
      downloadImage(imgUrl, "招商邀请码");
    },
    //重置
    reset() {
      this.phone = "";
      this.nickname = "";
      this.selectedValue = "";
      this.pageNum = 1;
      this.getTableData();
    },
    // 查看图片
    lookImg(imgUrl) {
      this.imgUsrcListrl = [imgUrl];
    },
    // 每页条数改变时
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
    },
    // 当前页改变时
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getTableData();
    },
    // 回车键搜索
    enterSearch(event) {
      if (event.keyCode === 13) {
        let isfocus1 =
          document.activeElement === this.$refs.searchInput1.$refs.input;
        let isfocus2 =
          document.activeElement === this.$refs.searchInput2.$refs.input;
        // 判断光标是否聚焦在输入框中
        if (isfocus1 || isfocus2) {
          this.getTableData(1);
        }
      }
    },
    // 获取表格数据
    async getTableData(page) {
      if (page) this.pageNum = page;
      this.loadShow = true;
      let res = await GetUserInfoApi({
        phone: this.phone.trim(),
        page: this.pageNum,
        nickname: this.nickname.trim(),
        role: this.selectedValue,
      });
      this.loadShow = false;
      if (!res) return;
      res.data.forEach((element, index) => {
        element.index = index + 1 + (this.pageNum - 1) * 10;
      });
      this.tableData = res.data;
      this.total = res.total;
    },

    // 更改积分
    async handelPoints(type, id, phone, name) {
      if(id != '' && phone != ''){
        this.handelType = type
        this.pointPhone = phone
        this.pointName = name
        this.login_id = id
      }
      if (type == 'sure') {
        this.loadShow = true;
        let res = await handelPointsInfoApi({
          login_id: this.login_id,
          money: this.pointValue,
          status: this.pointsType
        });
        this.loadShow = false;
        if (!res) return;
        this.getTableData()
        this.pointsVisibel = false
        this.pointValue = 0
      } else if (type == 'change') {
        this.pointsVisibel = true
      } else {
        this.pointsVisibel = true
      }
    }
  },
  beforeDestroy() {
    // 清楚dom事件
    document.removeEventListener("keyup", this.enterSearch);
  },
};
</script>

<style lang="less" scoped>
.mainData {
  display: flex;
  justify-content: space-between;
  color: #555;

  img {
    vertical-align: bottom;
    margin-right: 5px;
  }
}

.avatar {
  width: 30px !important;
  height: 30px !important;
  vertical-align: bottom;
  margin-right: 7px;
}
</style>
